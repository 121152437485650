body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans';
  font-size: 14px;
}

* {
  outline: none;
}

button,
input {
  font-family: 'Open Sans';
  font-size: 14px;
}